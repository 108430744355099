.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #BEBFD2;
  border-radius: 2px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
}
.custom-checkbox:checked+label::before {
  border-color: #4985FF;;
  background-color: #4985FF;;
  background-image: url('../../assets/check.svg');
  background-size: contain;
}