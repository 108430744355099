.sidebar-container {
	width: 100px;
	height: 100vh;
	z-index: 99999;
}

.logo-container {
	height: 95px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo {
	width: auto;
	height: 30%;
}

.navbar-container {}

.items-navbar {
	color: #ffffff;
	font-family: Raleway;
	font-size: 0.9rem;
}

.item:hover {
	background: linear-gradient(90deg,
			#bfd4ff 0%,
			#bfd4ff 1.99%,
			rgba(191, 212, 255, 0.1) 1.99%,
			rgba(191, 212, 255, 0.1) 100%);
}
.active{
	background: linear-gradient(90deg,
	#bfd4ff 0%,
	#bfd4ff 1.99%,
	rgba(191, 212, 255, 0.1) 1.99%,
	rgba(191, 212, 255, 0.1) 100%);
}
@media only screen and (max-width: 600px) {
	.sidebar-mobile {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9999;
		transition: all 0.3s;
	}

	.sidebar-mobile.out {
		transform: translateY(-100%);
		transition: all 0.3s;
	}

	.burger-btn {
		position: relative;
		cursor: pointer;
		z-index: 999999;
		width: 63px;
		height: 63px;
		background-image: url('../../assets/menu.svg');
		background-size: cover;
		background-repeat: no-repeat;
	}

	.menu {
		position: fixed;
		width: 80vw;
		height: 90vh;
		top: 0;
		transform: translateX(-100%);
		transition: all 0.4s;
	}

	.menu.active {
		transform: translateX(0);
	}

	.items-navbar {
		color: #4985ff;
	}

	.blur {
		width: 100vw;
		height: 100vh;
		background: rgba(177, 204, 244, 0.22);
		backdrop-filter: blur(3.5px);
	}

	.menu__content {
		padding: 50px 0;
		width: 70vw;
		height: 85vh;
		background-color: #ffffff;
		z-index: 5;
		transition: all 0.4s;
	}

	.contacts {
		gap: 0 !important;
	}

	.contacts span {
		font-size: 10px;
		padding: 0;
	}

	.contacts span:last-child {
		margin-top: 0;
	}
}

@media only screen and (orientation:landscape) {
	.sidebar-mobile {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9999;
		transition: all 0.3s;
	}

	.sidebar-mobile.out {
		transform: translateY(-100%);
		transition: all 0.3s;
	}

	.burger-btn {
		position: relative;
		cursor: pointer;
		z-index: 999999;
		width: 63px;
		height: 63px;
		background-image: url('../../assets/menu.svg');
		background-size: cover;
		background-repeat: no-repeat;
	}

	.menu {
		position: fixed;
		width: 80vw;
		height: 90vh;
		top: 0;
		transform: translateX(-100%);
		transition: all 0.4s;
	}

	.menu.active {
		transform: translateX(0);
	}

	.blur {
		width: 100vw;
		height: 100vh;
		background: rgba(177, 204, 244, 0.22);
		backdrop-filter: blur(3.5px);
	}

	.menu__content {
		padding: 50px 0;
		width: 70vw;
		height: 85vh;
		background-color: #ffffff;
		z-index: 5;
		transition: all 0.4s;
	}

	.contacts {
		gap: 0 !important;
	}

	.contacts span {
		font-size: 10px;
		padding: 0;
	}

	.contacts span:last-child {
		margin-top: 0;
	}
}

@media only screen and (max-width: 500px) {
	.item img {
		display: none;
	}

	.item a {
		font-size: 15px;
	}

	.menu__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	.contacts {
		display: flex;
		flex-direction: column;
		gap: 10px;
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 130%;
		display: flex;
		align-items: center;
		text-align: center;
		color: #4f4f70;
	}
}