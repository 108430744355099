.homePageContainer {
	display: flex;
	flex-direction: row;
	max-width: 2000px;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	max-height: 100vh;
}

.mainContentContainer {
	overflow: auto;
	position: relative;
	width: 100%;
}

#job1,
#internship1 {
	border-left: 1px #e7eeff solid;
	border-right: 1px #e7eeff solid;
	/* width:35%; */
	/* padding-left:3%;
   padding-right:3%; */
}

.jobs p {
	font-size: 12px;
}

h2 {
	font-size: 24px;
}

.newscard {
	transition: all 0.5s;
}
.newscard:hover {
	width: 310px;
}

.salary,
.vacancy,
.type,
.title,
.jobs p {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -moz-box;
	-moz-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	line-clamp: 1;
	box-orient: vertical;
}

.title {
	max-width: 200px;
}

.imageBanner {
	width: 220px;
	height: 90px;
	position: relative;
	background-size: cover;
	background-image: url('../../assets/background.png');
}

.logoBanner {
	position: absolute;
	width: 150px;
	height: 150px;
	background-size: contain;
	background-image: url('../../assets/banner.png');
	bottom: 0;
	left: auto;
	right: 10%;
}

@media screen and (min-width: 1800px) {
	.job {
		width: 24.5%;
	}
}

@media screen and (max-width: 1799px) {
	.job:last-child {
		display: none;
	}
}

@media screen and (max-width: 1300px) {
	.title {
		max-width: 150px;
	}
}

@media screen and (max-width: 1150px) {
	.title {
		max-width: 100px;
	}
}

@media screen and (max-width: 1040px) {
	#job1 {
		border-left: none;
		border-right: none;
	}
}

@media screen and (max-width: 1000px) {
	.jobs {
		flex-direction: column;
		align-items: center;
	}
	.job {
		width: 60%;
		height: 200px;
	}
	.vacancyContainer {
		width: 100%;
	}

	.job:nth-child(2n + 1) {
		padding-top: 2rem;
		padding-bottom: 2rem;
		border-top: 1px #e7eeff solid;
		border-bottom: 1px #e7eeff solid;
	}
	.job:nth-child(2) {
		margin: 0;
	}
	.typeContainer {
		margin-bottom: 1rem;
	}
}

@media screen and (max-width: 900px) {
	.banner {
		width: 80%;
	}
	.titleBanner {
		right: 2 !important;
		left: none;
		font-size: 0.5rem;
	}
	.description {
		font-size: 0.8rem;
	}
	.linkCourse {
		width: auto;
		right: 1rem;
	}

	.salary,
	.vacancy,
	.type,
	.title {
		-webkit-line-clamp: 1;
		line-clamp: 1;
	}
	.titleVacancies {
		margin-left: 10%;
	}
	.linkVacancies {
		width: 9rem;
		margin-left: 70%;
	}
}

@media screen and (max-width: 700px) {
	.banner {
		display: flex;
		flex-direction: column;
		height: 200px;
		width: 80%;
		align-items: center;
		justify-content: center;
	}

	.description {
		top: 0;
		flex-wrap: wrap;
		position: absolute;
		width: 100%;
		text-align: center;
		padding: 1rem;
		font-size: 0.8rem;
		justify-content: center;
	}
	.linkCourse {
		width: 9rem;
		height: 100%;
		right: 0;
		position: absolute;
		top: 4rem;
		padding-right: 2rem;
	}
	.linkCourse img {
		margin-top: -3px;
	}

	.imageBanner {
		position: absolute;
		bottom: 0;
		width: 100%;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.logoBanner {
		left: 1rem;
	}

	.titleBanner {
		font-size: 1rem;
		width: 7rem;
		position: absolute;
		right: 2rem;
		left: auto;
		bottom: 2rem;
	}
	.linkVacancies {
		margin-left: 60%;
	}
}

@media only screen and (max-width: 600px) {
	.containerHomePageVacancies {
		display: flex;
		flex-direction: column;
	}

	.titleVacancies {
		margin: 0;
		padding: 0 30px;
	}

	.job:nth-child(2n + 1) {
		border-top: none;
		border-bottom: none;
	}

	.jobs {
		flex-direction: column;
		width: 90vw;
		margin-left: auto;
		margin-right: auto;
		gap: 15px;
	}
	.job {
		width: 100%;
	}
	.job:nth-last-child(-n + 2) {
		display: none;
	}

	.linkVacancies {
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: reverse;
		margin: 1vh 9vw 0 auto;
	}

	.linkVacancies p {
		margin: 0;
		padding: 0;
		margin-left: auto;
		padding-right: 8px;
	}

	.showmore {
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
	}
}

@media screen and (max-width: 490px) {
	.containerHomePageVacancies {
		padding: 0;
	}

	.titleVacancies {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 130%;
		color: #808099;
	}

	.containerHomePageVacancies:nth-child(1) {
		margin-top: 0;
	}

	.vacancy {
		font-size: 1rem;
	}

	.salary {
		font-size: 0.8rem;
	}

	.jobs p {
		font-size: 0.7rem;
	}

	.button {
		height: 1.8rem;
		width: 8rem;
		font-size: 0.7rem;
	}
	.linkVacancies {
		margin-bottom: 40px;
	}
}

@media screen and (max-width: 410px) {
	.banner {
		height: 190px;
		width: 80%;
	}
	.description {
		padding: 0.3rem;
		font-size: 0.7rem;
	}
	.imageBanner {
		width: 150px;
		height: 67px;
	}
	.logoBanner {
		width: 100px;
		height: 100px;
		bottom: 1rem;
		background-repeat: no-repeat;
	}
	.linkCourse {
		width: 89px;
		height: 50%;
		padding-right: 0;
		right: 1rem;
	}
	.linkCourse img {
		margin-top: -6px;
	}
	.titleBanner {
		font-size: 0.6rem;
		width: 4rem;
		right: -5rem !important;
	}
}

@media (orientation: portrait) and (max-width: 390px) {
	.titleBanner {
		right: -4rem !important;
	}
}

@media only screen and (max-width: 370px) {
	.titleBanner {
		right: -2.5rem !important;
	}
}
