.pdf-wrapper {
	padding: 28px 28px 0 28px;
	margin-top: 12px;
	width: 100%;
	margin-top: 16px;
	padding-top: 16px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pdf-line{
	position: absolute;
	top: 0;
	width: 50%;
	height: 1px;
	background-color: #808099;
}

.pdf-title {
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	width: 100%;
	color: #282A43;
}

.pdf-salary {
	font-size: 16px;
	margin-top: 12px;
	text-align: center;
	color: #282A43;
}

.pdf-job-type {
	font-size: 16px;
	margin-top: 12px;
	color: #808099;
	text-align: center;
}

.pdf-company {
	width: 66.66667%;
	font-size: 16px;
	color: #4F4F70;
	text-align: center;
}

.pdf-label {
	display: inline-block;
	width: 100%;
	padding-left: 8px;
}

.pdf-label-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 12px;
	flex-wrap: wrap;
	color: #4F4F70;
}

.pdf-label-inner {
	display: flex;
	align-items: center;
	width: 33.33333%;
}

.pdf-label-full-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 44px;
}

.pdf-icon {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pdf-button {
	/* padding: 10px 44px;
	margin-top: 40px;
	margin-bottom: 40px;
	color: #4985FF;
	background-color: transparent;
	border: 1px solid #4985FF;
	border-radius: 4px;
	transition: box-shadow 0.3s ease-in-out; */
	margin-top: 40px;
}

.pdf-button:hover {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pdf-link {
  font-size: 15px;
  color: #4985FF;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
