.custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-radio+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-radio+label::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #BEBFD2;
  border-radius: 100%;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
}
.custom-radio+label::after {
  content: '';
  position: absolute;
  display: none;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: #4985FF;
  left: 24px;
}
.custom-radio:checked+label::after {
  display: inline-block;
}