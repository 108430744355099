.headerContainer {
	height: 63px;
	width: 100%;
	z-index: 999;
	top: 0;
	background: #ffffff;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.header {
	display: flex;
	flex-direction: row;
}
.configureContent {
	display: flex;
	justify-content: center;
	align-items: center;
}

.signin {
	background-image: url('../../assets/signin.png');
	background-position: 25px;
	background-repeat: no-repeat;
}
@media only screen and (max-width: 600px) {
	.headerContainer {
		height: 63px;
		display: flex;
		justify-content: center;
		border-bottom: none;
		position: sticky;
	}

	.header {
		display: flex;
		padding: 1rem;
		justify-content: center;
	}

	.search-input div {
		display: none;
	}
}
