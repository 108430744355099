/* Шапка */

.header_container {
    display: flex;
    /* min-width: 1440px; */
    justify-content: space-between;
    text-align: center;
    align-items: center;
}


.header_items {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}


hr {
    color: #E5E5E5;
}

.header-telegram {
    font-family: Raleway;
    font-weight: 500px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: black;
}

/* Подписка */
.title_profile {
    width: 450px;
    min-height: 62px;
    margin-top: 39px;
    margin-left: 44px;
    gap: 10px;
    position: relative;
}

.send {
    font-family: Raleway;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #4F4F70;
}

.send span {
    color: #4985FF;
}


/* Поиск */
.search {
    display: flex;
    border: 1px solid #E7EEFF;
    border-radius: 10px;
    /* margin-left: 504px; */
}


.web__search {
    border-radius: 10px;
    width: 404px;
    height: 40px;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
}


.web__search ::placeholder {
    color: #BEBFD2;
    font-family: Raleway;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.search-logo {
    border: none;
    outline: none;
    width: 40px;
    height: 40px;
}


.search-logo img {
    display: inline-block;
    margin-right: 25px;
}


/* Языки  */
.select_language {
    min-width: 80px;
    height: 44px;
    margin-left: 20px;
    margin-right: 5px;
    padding: 11px 10px;
    color: black;
}

.language {
    font-family: Raleway;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: black;
}

/* Вход */
#log_in {
    width: 110px;
    height: 44px;
    background-color: #4985FF;
    color: white;
    border-radius: 4px;
    margin-right: 44px;
    margin-left: 20px;
    position: relative;
    padding-left: 51px;
    padding-right: 25px;
}


.log_in-icon {
    position: absolute;
    width: 13.5px;
    height: 13.5px;
    top: 15px;
    left: 26px;
    color: white;
}

.telegram_logo {
    position: absolute;
    margin-left: 445px;
    margin-top: -100px;
}

#mainContent_telegram {
    overflow-x: hidden;
}

.telegram_cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;
    /* min-width: 1252px; */
    height: max-content;
    margin-top: 40px;
}


.telegram_card {
    width: 230px;
    height: 285px;
    border-radius: 10px;
    padding: 30px, 16px;
    box-shadow: 0px 2px 20px rgba(73, 133, 255, 0.05);
    background: #F8FAFF;
    text-align: center;
}

.telegram_card img {
    width: 80px;
    height: 80px;
    margin-top: 42px;
    margin-left: 75px;
}

.item-title {
    width: 214px;
    height: 36px;
    margin: 20px 8px;
}

.name_UPP {
    font-family: Raleway;
    font-weight: 600px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #4985FF;
}


.item-subscribe {
    width: 140px;
    display: inline-block;
    background: #4985FF;
    border-radius: 4px;
    margin-bottom: 42px;
    color: white;
    padding: 0.6rem 0.8rem;
}

.item-subscribe-mobile {
    display: none;
}

.vector-mobil {
    display: none;
}

.vector_card-mobil {
    display: none;
}

@media screen and (min-width: 2000px) {
    .homePageContainer {
        max-width: none;
    }
}


/* Nest Hub Max  */
@media screen and (max-width: 1280px) {
    .vector_card-mobil {
        display: none;
    }
}


/* Nest Hub */
@media screen and (max-width: 1050px) {
    .web__search {
        max-width: 350px;
    }

    .vector_card-mobil {
        display: none;
    }
}


/* Surface PRO7  */
@media screen and (max-width: 1000px) {
    .web__search {
        max-width: 300px;
    }

    .vector_card-mobil {
        display: none;
    }
}

/* iPad Air */
@media screen and (max-width: 950px) {
    .web__search {
        max-width: 250px;
    }

    .vector_card-mobil {
        display: none;
    }

    .item-subscribe-mobile {
        display: none;
    }
}

@media only screen and (max-width: 890px) {
    .web__search {
        max-width: 180px;
    }
}


@media screen and (max-width: 820px) {
    .web__search {
        max-width: 150px;
    }

    .telegram_cards {
        overflow: auto;
    }

    #log_in {
        margin-right: 40px;
    }

}

@media screen and (max-width: 725px) {
    .web__search {
        max-width: 120px;
    }
}


@media screen and (max-width: 695px) {
    .web__search {
        max-width: 90px;
    }

    .select_language {
        margin-left: 0px;
    }

    .telegram_cards {
        overflow-x: hidden;
    }

}

@media only screen and (max-width: 600px) {
    .header_items {
        display: none;
    }

    .telegram_cards {
        overflow-x: hidden;
    }
}


/* Surfase Duo */
@media (orientation: portrait) and (max-width: 540px) {
    .header_items_vector {
        padding-right: 44px;
        background-color: white;
        height: 63px;
    }

    .vector-mobil {
        display: block;
    }

    .vector_card-mobil {
        display: block;
    }

    .header_container {
        position: sticky;
        top: 0;
        z-index: 1;
        margin-right: 0px;
    }

    .header_container .logohead {
        padding-left: 33px;
    }

    hr {
        display: none;
    }

    .telegram_logo {
        display: none;
    }

    .telegram_card img {
        width: 75px;
        height: 75px;
    }

    .item-subscribe-mobile {
        display: none;
    }

    .telegram_cards {
        overflow-x: hidden;
    }
}



/* 13Pro Max, 14Pro Max */
@media (orientation: portrait) and (max-width:450px) {
    .title_profile {
        display: none;
    }

    .header_container .logohead {
        padding-left: 33px;
    }

    .header_items_vector {
        padding-right: 0px;
    }

    .vector-mobil {
        margin-right: 44px;
    }

    .header-telegram {
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .telegram_cards {
        display: flex;
        flex-flow: nowrap;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        margin: 20px 16px 20px 16px;
        gap: 0px;
        overflow-x: hidden;
    }

    .telegram_card {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 382px;
        height: 74px;
        border-radius: 10px;
        padding: 12px, 10px, 12px, 10px;
        background: none;
        border-bottom: 1px dashed #F8FAFF;
    }

    .telegram_card img {
        width: 50px;
        height: 50px;
        margin-top: 12px;
        margin-left: 24px;
        border-radius: 100px;
    }

    .item-title {
        width: 220px;
        height: 18px;
        margin: 28px 16px 28px 14px;
    }

    .name_UPP {
        align-items: center;
        font-style: normal;
        font-size: 14px;
        line-height: 18.2px;
    }

    .header_items_vector {
        height: 63px;
        background-color: white;
    }

    .telegram_card .item-subscribe-mobile img {
        display: block;
        width: 21px;
        margin-left: 24px;
        margin-top: 0px;
    }

    .item-subscribe {
        display: none;
    }

    .item-subscribe-mobile {
        display: block;
    }
}


/*айфон XR, Samsung GalaxyA5    */

@media (orientation: portrait) and (max-width:414px) {
    .vector-mobil {
        margin-right: 40px;
    }

    .telegram_card .item-subscribe-mobile img {
        margin-right: 27.5px;
        margin-left: 16px;
    }
}


/* Google Pixel 5, айфон 12PRO */
@media (orientation: portrait) and (max-width: 393px) {
    .telegram_card {
        min-width: 360px;
    }

    .item-title {
        width: 200px;
    }

    .name_UPP {
        font-size: 12px;
    }
}


/* айфон SE */
@media (orientation: portrait) and (max-width: 375px) {
    .header_items_vector {
        padding-right: 0px;
    }

    .telegram_card {
        min-width: 343px;
    }

    .telegram_card img {
        margin-left: 20px;
    }

    .item-title {
        width: 190px;
    }
}


/* Samsung GalaxyS8+ */
@media (orientation: portrait) and (max-width: 360px) {
    .vector-mobil {
        margin-right: 35px;
    }

    .item-title {
        width: 170px;
    }
}


/* Galaxy Fold */
@media (orientation: portrait) and (max-width: 280px) {
    .vector-mobil {
        margin-right: 30px;
    }

    .telegram_cards {
        margin-top: 15px;
    }

    .telegram_card {
        min-width: 243px;
        height: 60px;
    }

    .telegram_card img {
        width: 35px;
        height: 35px;
        margin-top: 12px;
        margin-left: 10px;
    }

    .item-title {
        width: 150px;
    }

    .name_UPP {
        font-size: 9px;
        line-height: 15px;
    }

    .telegram_card .item-subscribe-mobile img {
        width: 15px;
        margin-right: 10px;
        margin-left: 10px;
    }
}