.swiper {
	transition: transform 0.3s ease;
	transform: scale(1);
	position: relative;
	width: 900px;
	margin-top: 5%;
	height: 100%;
}
.sw-container {
	height: 460px;
	margin-left: auto;
	margin-right: auto;
}
.sw-container >div{
	margin-top: 25px;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #f8faff;
	border: 1px solid #e7eeff;
	border-radius: 15px;
	box-shadow: 0px 2px 20px rgba(73, 133, 255, 0.05);
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	height: 80%;
}
.swiper-slide div {
	display: none;
	width: 80%;
	text-align: left;
	padding: 5% 12%;
}
.swiper-slide-active div {
	display: block;
}
.swiper-slide h2 {
	font-size: 28px;
	font-style: normal;
}
.swiper-slide p {
	margin-top: 15px;
	font-size: 16px;
	width: 65%;
	line-height: 150%;
}

.swiper-slide-active {
	background-color: white;
	background-position: right -25px bottom -30px;
	background-repeat: no-repeat;
	width: 80%;
}

.swiper-pagination-bullet {
	width: 86px;
	height: 86px;
	font-size: 10px;
	margin: -15% 0% 0% 3% !important;
	border: 1px solid #e7eeff;
	background-color: #f8faff;
	opacity: 1;
	box-shadow: 0px 0px 10px rgba(73, 133, 255, 0.2);
	position: relative;
}

.empty {
	display: none;
}

.swiper-pagination-bullet-active {
	background-color: white;
	top: -10px;
}

.swiper-pagination-bullet img {
	margin: auto;
	opacity: 0.3;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
}
.swiper-pagination-bullet-active img {
	opacity: 1;
}
.swiper-pagination-bullet div {
	top: 60%;
	position: relative;
}
.swiper-pagination {
	bottom: -50px !important;
	height: 150px;
}
.swiper-pagination-bullet-active div {
	position: relative;
	top: 60%;
}

.swiper-slide-active#slide1 {
	background-size: contain;
	background-image: url('../../assets/slide1.png');
}
.swiper-slide-active#slide2 {
	background-size: contain;
	background-image: url('../../assets/slide2.png');
}
.swiper-slide-active#slide3 {
	background-size: contain;
	background-image: url('../../assets/slide6.png');
}
.swiper-slide-active#slide4 {
	background-size: contain;
	background-image: url('../../assets/slide3.png');
}
.swiper-slide-active#slide5 {
	background-size: contain;
	background-image: url('../../assets/slide5.png');
}
.swiper-slide-active#slide6 {
	background-size: contain;
	background-image: url('../../assets/slide6.png');
}

@media screen and (max-width: 1100px) {
	.swiper {
		max-width: 850px;
	}
}

@media screen and (max-width: 1000px) {
	.swiper {
		padding: 0 10px;
	}

	.mySwiper {
		width: auto;
		padding: 10px 80px;
	}

	.swiper-pagination-bullet {
		align-items: center;
	}

	.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		font-size: none;
		margin: none;
		border: none;
		background-color: none;
		opacity: none;
		box-shadow: none;
		background: #bfd4ff;
		bottom: -45px !important;
	}
	.swiper-pagination-bullet img {
		display: none;
	}
	.swiper-pagination-bullet div {
		display: none;
	}
	.swiper-pagination-bullet-active {
		margin-bottom: -40px !important;
		background: #4985ff;
	}
	.swiper-slide div {
		width: 100%;
	}
}

@media screen and (max-width: 810px) {
	.swiper-slide h2 {
		font-size: 25px !important;
	}
	.swiper-slide p {
		font-size: 16px !important;
		line-height: 150%;
		margin-bottom: 60px;
	}
	.buttonSwiper {
		padding: 0.5rem 0.5rem;
		font-size: 15px;
		width: 30%;
	}
	.swiper-slide-active#slide1 {
		background-size: 110% 80%;
	}
	.swiper-slide-active#slide2 {
		background-size: 110% 60%;
	}
	.swiper-slide-active#slide3 {
		background-size: 110% 80%;
	}
	.swiper-slide-active#slide4 {
		background-size: 110% 80%;
	}
	.swiper-slide-active#slide5 {
		background-size: 110% 80%;
	}
	.swiper-slide-active#slide6 {
		background-size: 110% 80%;
	}
}

@media screen and (max-width: 700px) {
	.sw-container {
		height: 320px;
	}
	.swiper-slide {
		height: 250px;
	}
	.swiper-pagination-bullet {
		bottom: -5px !important;
	}
	.swiper-pagination-bullet-active {
		margin-bottom: 0px !important;
	}
	.swiper-slide h2 {
		font-size: 18px !important;
	}
	.swiper-slide p {
		font-size: 14px !important;
		line-height: 150%;
		margin-bottom: 20px;
	}
	.buttonSwiper {
		padding: 0.5rem 0.5rem;
		font-size: 10px;
		width: 30%;
	}
	.swiper-pagination {
		top: 280px;
		height: 40px;
	}

	.swiper-slide-active#slide1 {
		background-size: 110% 70%;
	}
	.swiper-slide-active#slide2 {
		background-size: 110% 70%;
	}
	.swiper-slide-active#slide3 {
		background-size: 110% 70%;
	}
	.swiper-slide-active#slide4 {
		background-size: 110% 70%;
	}
	.swiper-slide-active#slide5 {
		background-size: 110% 70%;
	}
	.swiper-slide-active#slide6 {
		background-size: 110% 70%;
	}
}

@media screen and (max-width: 600px) and (max-height: 1024px) {
	.mySwiper {
		padding: 0 30px;
	}

	.swiper-slide {
		max-height: 300px;
		margin: 0;
		background-color: #f2f6ff;
		width: 100%;
	}

	.swiper-slide div {
		padding: 0;
		margin: 0;
		height: 100%;
	}

	.modileSwiperContainer {
		height: 200px;
	}

	.swiper-pagination {
		top: 270px !important;
		height: 40px;
	}

	.swiper-pagination-bullet-active {
		top: 0 !important;
	}

	.modileSwiperContainer {
		display: flex !important;
		flex-direction: row;
	}

	.swiper-pagination-bullet {
		bottom: 0 !important;
	}

	.descriptionMobileSlide {
		width: 60% !important;
		margin: 0;
		height: 90%;
		display: flex !important;
		flex-direction: column;
		padding: 10px !important;
		align-items: center;
		justify-content: center;
		gap: 15px;
	}

	.logoMobileSlideContainer {
		width: 40% !important;
		position: relative;
		/* overflow: hidden; */
		margin: 0px;
		height: 100%;
		align-items: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.logoMobileSlide {
		position: absolute;
		object-fit: contain;
		left: 40%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 90%;
		width: 100%;
	}

	.swiper-slide h2 {
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 600;
		line-height: 120%;
		font-size: 20px !important;
		margin-bottom: 5px;
		width: 90%;
	}

	.swiper-slide p {
		margin-top: 0px;
		font-size: 13px !important;
		width: 100%;
		line-height: 150%;
		width: 90%;
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 400;
	}

	.buttonSwiper {
		height: auto;
		padding: 0.2rem 0.2rem;
		font-size: 10px;
		width: 50%;
	}

	.swiper-slide-active#slide1 {
		background-image: none;
	}

	.swiper-slide-active#slide2 {
		background-image: none;
	}

	.swiper-slide-active#slide3 {
		background-image: none;
	}
	.swiper-slide-active#slide4 {
		background-image: none;
	}
	.swiper-slide-active#slide5 {
		background-image: none;
	}
	.swiper-slide-active#slide6 {
		background-image: none;
	}
}

@media screen and (max-width: 490px) {
	.mySwiper {
		margin-top: 70px;
	}
	.swiper-pagination {
		margin: 0;
	}

	.swiper-slide h2 {
		font-size: 19.5px !important;
	}
	.swiper-slide p {
		font-size: 14px !important;
	}
	.buttonSwiper {
		height: auto;
		padding: 0.2rem 0.2rem;
		font-size: 10px;
		width: 50%;
	}
}

@media screen and (max-width: 450px) {
	/* .swiper-slide h2 {
		font-size: 10px !important;
		margin-bottom: 5px;
		line-height: 130% !important;
	}
	.swiper-slide p {
		margin-top: 0px;
		font-size: 19px !important;
		line-height: 110% !important;
	}
	.buttonSwiper {
		height: auto;
		padding: 0.2rem 0.2rem;
		font-size: 10px;
		width: 50%;
	} */
}

@media only screen and (max-width: 400px) {
	.swiper-slide h2 {
		font-size: 16px !important;
		line-height: 1.2;
	}
	.swiper-slide p {
		font-size: 12px !important;
		line-height: 1;
	}
	.mySwiper {
		padding: 0 30px;
	}
	.swiper-slide {
		max-height: 200px;
	}
}
